export function getWhen({
                            when = undefined,
                            then = (() => undefined),
                            or = (() => undefined),
                            defaults = undefined,
                            args = [],
                            from = undefined,
                        }) {
    let $args = Array.from(args);
    when = typeof when === 'function' ? when(...$args) : when;
    let $then = typeof then === 'function' ? then : () => then;
    let $or = typeof or === 'function' ? or : () => or;
    let $defaults = typeof defaults === 'function' ? defaults : () => defaults;
    from = typeof from === 'function' ? from(...$args) : from;

    let $from = (v) => {
        if (from === undefined) {
            return v;
        }
        return v in from ? from[v] : undefined;
    };

    if (when) {
        let result = $then(...$args);
        if (result !== undefined) {
            return $from(result);
        }

        return $from($defaults(...$args));
    }

    let result = $or(...$args);
    if (result !== undefined) {
        return $from(result);
    }

    return $from($defaults(...$args));
}

/**
 *
 * @param data {{}}
 * @param keys {[]|String}
 * @param $default {*}
 * @returns {{data: *, key: string|*|undefined}}
 */
export function dataGet(data = {}, keys = [], $default = undefined) {
    let $data = data || {};
    keys = Array.from(keys);
    
    for (let i = 0, kL = keys.length; i < kL; i++) {
        let $key = keys[i];
        if ($key in $data) {
            return {
                data: $data[$key],
                key: $key
            };
        }
    }

    if (typeof $default === 'string' && $default in $data) {
        return {
            data: $data[$default],
            key: $default
        };
    }

    return {
        data: $default,
        key: undefined
    };
}
