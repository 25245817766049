import {Builder} from "./Builder";

export class MasterCardBuilder extends Builder {
    constructor(payload = {}, sdk) {
        super(payload, sdk);

        let {successIndicator} = payload;
        localStorage.setItem('successIndicator', successIndicator);
        
        return this
            .setCompleteCallback((resultIndicator, sessionVersion) => window && window.Location && (window.location.href = this.getReturnUrl({resultIndicator, sessionVersion, status: 'complete'})) || {resultIndicator, sessionVersion, status: 'complete'})
            .setErrorCallback(error => window && window.Location && (window.location.href = this.getReturnUrl({error: JSON.stringify(error), status: 'error'})) || {error: JSON.stringify(error), status: 'error'})
            .setCancelCallback(() => window && window.Location && (window.location.href = this.getReturnUrl({status: 'cancel'})) || {status: 'cancel'})
            .setTimeoutCallback(() => window && window.Location && (window.location.href = this.getReturnUrl({status: 'timeout'})) || {status: 'timeout'});
    }

    static make(payload = {}, sdk) {
        return new MasterCardBuilder(payload, sdk);
    }

    /**
     * Prepare before execute.
     * @returns {Promise<boolean|HTMLScriptElement>}
     */
    async prepare() {
        if (this.instance) {
            return this.instance;
        }

        let $window;
        try {
            $window = window || {};
        } catch (e) {
            $window = {};
            console.error(e)
        }

        $window.completeCallback = this.getCompleteCallback();
        $window.errorCallback = this.getErrorCallback();
        $window.cancelCallback = this.getCancelCallback();
        $window.timeoutCallback = this.getTimeoutCallback();

        const script = document.createElement('script');

        script.src = await this.getLibraryUrl();
        script.setAttribute('data-complete', "completeCallback");
        script.setAttribute('data-error', "errorCallback");
        script.setAttribute('data-cancel', "cancelCallback");
        script.setAttribute('data-timeout', "timeoutCallback");
        script.async = true;

        script.addEventListener('load', () => {
            this.configure(window.Checkout);
            this.execute(window.Checkout)
                .then((trigger) => {
                    this.getLoadCallback()(trigger);

                    return trigger && trigger();
                });
        })

        return this.instance = script;
    }

    /**
     * Execute the builder.
     * @returns {Promise<*>}
     */
    async execute() {
        await this.prepare();
        const Checkout = arguments.length ? arguments[0] : (window.Checkout || {configure: (() => undefined)})
        let modal_mode = this.sdk.getConfig('modal_mode');

        return this.sdk.loadInfo()
            .then(({master_card_show_methods = []}) => master_card_show_methods[Number(modal_mode)] || 'showLightbox')
            .then((showPageMethod) => (Checkout[showPageMethod] || (() => undefined)))
    }
}

export default MasterCardBuilder;