import axios from 'axios';

const $config = {
    headers: {
        // `responseType` indicates the type of data that the server will respond with
        // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
        //   browser only: 'blob'
        responseType: "json", // default

        // `validateStatus` defines whether to resolve or reject the promise for a given
        // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
        // or `undefined`), the promise will be resolved; otherwise, the promise will be
        // rejected.
        validateStatus: status => status >= 200 && status < 300, // default

        // `maxRedirects` defines the maximum number of redirects to follow in node.js.
        // If set to 0, no redirects will be followed.
        maxRedirects: 21, // 21 default
        
        /**
         * To send user credentials in a CORS request, set to `true`. To exclude user credentials from
         * a CORS request, _OR_ when cookies are to be ignored by the CORS response, set to `false`.
         * If `false`, will also ignore cookies in the CORS response.
         * Defaults to `false`.
         */
        withCredentials: true,

        /**
         * Whether or not the request was a CORS request.
         */
        crossDomain: true,
        
        "X-Requested-With": "XMLHttpRequest",
        
        "Content-Type": "application/json",
        
        // "Access-Control-Allow-Origin": "*",
        //
        // "xsrfCookieName": "connect.sid",
        // "xsrfHeaderName": "connect.sid",
    },
};

export const getAxiosDefaultHeaders = () => $config.headers;

export const makeAxios = (options = {}) => {
    let debug = options['debug'] || false;
    if ("debug" in options) {
        delete options['debug'];
    }

    const instance = axios.create({
        ...getAxiosDefaultHeaders(),
        ...options
    });

    let {get, post, defaults} = instance;
    instance.get = (...args) => {
        debug && console.log({method: "get", defaults, args});
        return get(...args);
    };
    instance.post = (...args) => {
        debug && console.log({method: "post", defaults, args});
        return post(...args);
    };

    return instance;
};

export const setAxiosToken = (token, AxiosInstance) => AxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export const setAxiosBaseUrl = (baseURL, AxiosInstance) => AxiosInstance.defaults.baseURL = baseURL;
