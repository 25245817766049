export class Builder {
    /**
     * @type {PaymentGatewaySDK}
     */
    sdk;
    payload = {};
    callbacks = {
        completeCallback: (resultIndicator, sessionVersion) => (location.href = this.getReturnUrl({resultIndicator, sessionVersion, status: 'complete'})),
        errorCallback: error => (location.href = this.getReturnUrl({error: JSON.stringify(error), status: 'error'})),
        cancelCallback: () => (location.href = this.getReturnUrl({status: 'cancel'})),
    };
    instance = false;
    /**
     * Triggered when script loaded
     * @type {Function}
     */
    loadCallback = (() => undefined);

    construct(payload = {}, sdk) {
        this.payload = payload;
        this.sdk = sdk;

        return this;
    }

    constructor(payload = {}, sdk) {
        return this.construct(payload, sdk);
    }

    static make(payload = {}, sdk) {
        return new Builder(payload, sdk);
    }

// region: callbacks
    setCompleteCallback(callback) {
        this.callbacks['completeCallback'] = callback;
        return this;
    }

    setErrorCallback(callback) {
        this.callbacks['errorCallback'] = callback;
        return this;
    }

    setCancelCallback(callback) {
        this.callbacks['cancelCallback'] = callback;
        return this;
    }

    setLoadCallback(callback) {
        this.loadCallback = callback;
        return this;
    }

    setTimeoutCallback(callback) {
        this.callbacks['timeoutCallback'] = callback;
        return this;
    }

    getLoadCallback() {
        return this.loadCallback || (() => undefined);
    }

    getCompleteCallback() {
        return this.callbacks['completeCallback'] || (() => undefined);
    }

    getErrorCallback() {
        return this.callbacks['errorCallback'] || (() => undefined);
    }

    getCancelCallback() {
        return this.callbacks['cancelCallback'] || (() => undefined);
    }

    getTimeoutCallback() {
        return this.callbacks['timeoutCallback'] || (() => undefined);
    }

// endregion: callbacks

    /**
     * Returns configuration object for the 3dParty
     * @returns {{session: {id: undefined}, interaction: {displayControl: {shipping: string, customerEmail: string, billingAddress: string}, merchant: {address: {line2: string, line1: string}, phone: string, name: string, logo: string, email: string, url: string}, locale: string, operation: string}, merchant, order: {amount: string, description: (*|string), currency: string, id: string, customerOrderDate: (*|string)}}}
     */
    getConfiguration() {
        let {
            merchant = {
                address: {
                    line1: '', // '200 Sample St',
                    line2: '', // '1234 Example Town'
                }, name: '', // 'Tuxedo',
                email: '', // 'info@dafa.sa',
                logo: '', // 'https://monsterhost.com/wp-content/uploads/2021/01/mh-logo.png',
                phone: '', // '966000000000',
                url: '' // 'https://www.dafa.sa'
            },
            session = undefined,
            session_id = undefined,
            merchant_id,
            amount = '0.00',
            order_id = '0',
            currency = undefined,
        } = this.payload;

        let {
            order_description = undefined,
            customerOrderDate = undefined,
            order = {
                description: undefined,
                customerOrderDate: undefined
            },
            locale = 'en_US',
        } = this.sdk.getAllData();

        locale = locale || 'en_US';
        if (!locale.includes('_')) {
            locale = locale === 'en' ? 'en_US' : (locale === 'ar' ? 'ar_SA' : `${locale}_${locale}`)
        }

        return {
            merchant: merchant_id,
            session: {
                id: (session && session['id']) || session_id || undefined,
            },
            order: {
                description: order['description'] || order_description || "",
                customerOrderDate: order['customerOrderDate'] || customerOrderDate || "",
                currency,
                id: order_id,
                amount,
            },
            interaction: {
                operation: 'PURCHASE',
                merchant,
                displayControl: {
                    billingAddress: 'HIDE', customerEmail: 'HIDE', shipping: 'HIDE'
                },
                locale,
            },
        };
    }

    /**
     * Returns return url from config.
     * @param params
     * @returns {*}
     */
    getReturnUrl(params = {}) {
        let return_url = this.sdk.getConfig('return_url');
        return_url = String(return_url).includes('?') ? `${return_url}&` : `${return_url}?`;
        return return_url + (new URLSearchParams(params));
    }

    /**
     * Returns lib js url from config.
     * @returns {Promise<string>}
     */
    getLibraryUrl() {
        let {version_id} = this.payload || {};

        return this.sdk.loadInfo()
            .then(({master_card_library, region, regions, is_live}) => {
                region = region || "SAU";
                regions = regions || ["SAU"];

                if (!region) {
                    throw new Error(`Region not set!`);
                }

                if (!regions.includes(region)) {
                    throw new Error(`Not allowed to use the given region [${region}]!`);
                }

                if (!master_card_library || !(region in master_card_library)) {
                    throw new Error(`No configuration for the given region [${region}]!`);
                }

                let {live, test} = master_card_library[region];

                return String(is_live ? live : test).replaceAll('{version_id}', version_id);
            });
    }

    /**
     * Prepare the lib.
     * @param Checkout
     * @returns {*}
     */
    configure(Checkout) {
        return (Checkout || window.Checkout || {configure: (() => undefined)})
            .configure(this.getConfiguration());
    }

    /**
     * Prepare before execute.
     * @returns {Promise<boolean|HTMLScriptElement>}
     */
    async prepare() {
        if (this.instance) {
            return this.instance;
        }

        let $window;
        try {
            $window = window || {};
        } catch (e) {
            $window = {};
            console.error(e)
        }

        $window.completeCallback = this.getCompleteCallback();
        $window.errorCallback = this.getErrorCallback();
        $window.cancelCallback = this.getCancelCallback();

        const script = document.createElement('script');

        script.src = await this.getLibraryUrl();
        script.setAttribute('data-complete', "completeCallback");
        script.setAttribute('data-error', "errorCallback");
        script.setAttribute('data-cancel', "cancelCallback");
        script.async = true;

        script.addEventListener('load', () => {
            this.configure(window.Checkout);
            this.execute(window.Checkout)
                .then((trigger) => trigger && trigger());
        })

        return this.instance = script;
    }

    /**
     * Execute the builder.
     * @returns {Promise<*>}
     */
    async execute() {
        await this.prepare();
        const Checkout = arguments.length ? arguments[0] : (window.Checkout || {configure: (() => undefined)})
        let modal_mode = this.sdk.getConfig('modal_mode');

        return this.sdk.loadInfo()
            .then(({master_card_show_methods = []}) => master_card_show_methods[Number(modal_mode)] || 'showLightbox')
            .then((showPageMethod) => (Checkout[showPageMethod] || (() => undefined)))
    }
}

export default Builder;