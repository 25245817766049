let DEFAULT_PAYMENT_GATEWAY = "Default";
let DEFAULT_PAYMENT_GATEWAY_TYPE = "Hosted";

export const PAYMENT_DEFAULT = {
    get gateway() {
        return DEFAULT_PAYMENT_GATEWAY;
    },
    set gateway(v) {
        DEFAULT_PAYMENT_GATEWAY = v;
    },
    get type() {
        return DEFAULT_PAYMENT_GATEWAY_TYPE;
    },
    set type(v) {
        DEFAULT_PAYMENT_GATEWAY_TYPE = v
    }
}
